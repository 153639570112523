<template>
  <ElDialog
    id="add-unit-dialog"
    top="30vh"
    :center="true"
    :show-close="false"
    :visible="visible"
    width="90%"
    :fullscreen="$viewport.lt.md"
    @close="close"
  >
    <div slot="title">
      <h2 class="mb-1">
        Add Units
      </h2>
      <h3
        class="text-gray-dark font-weight-normal"
      >
        {{ `For ${property.short_address}` }}
      </h3>
    </div>

    <div class="units-addition-form">
      <PropertyTypeField
        v-if="uiFlags.isInitiallySingleUnit"
        :is-form-field="false"
        :is-add-property-form="false"
        :property.sync="property"
        class="row w-100 justify-content-center align-items-center mt-1 mb-3 no-gutters"
        inner-class="col-10"
      />
      <div
        v-for="(_name, index) in unitNames"
        :key="`unit-add-${index}`"
        class="row justify-content-center align-items-center mt-1 mb-3 no-gutters"
      >
        <SdFloatLabel :class="isDeleteableField(index) ? 'col-9' : 'col-10'">
          <ElInput
            :id="`unit-name-add-${index}`"
            v-model="unitNames[index]"
            :placeholder="uiFlags.isInitiallySingleUnit && index === 0 && !firstUnitName ? 'First Unit Name' : 'New Unit Name'"
          />
        </SdFloatLabel>
        <div
          v-if="isDeleteableField(index)"
          class="col-1 pointer"
          @click="removeUnitFromArray(index)"
        >
          <i class="ml-2 sdicon-trash text-danger font-21" />
        </div>
        <ElDivider
          v-if="index === 0 && uiFlags.isInitiallySingleUnit"
          class="col-10 mt-4 mb-1"
        />
      </div>
    </div>
    <div class="row justify-content-center align-items-center mt-1 mb-3 no-gutters">
      <div
        class="col-10 font-17 text-primary pointer"
        @click="addUnitToArray"
      >
        + Add Another Unit
      </div>
    </div>

    <span
      slot="footer"
      class="row justify-content-center"
    >
      <div class="col-5">
        <ElButton
          class="w-100"
          size="medium"
          @click="close"
        >
          Cancel
        </ElButton>
      </div>
      <div class="col-5">
        <ElButton
          class="w-100"
          size="medium"
          type="primary"
          :disabled="uiFlags.isAdding || !isUnitsNameFilled"
          :loading="uiFlags.isAdding"
          @click="add"
        >
          Add units
        </ElButton>
      </div>
    </span>
  </ElDialog>
</template>

<script>
import {
 reactive, ref, computed, nextTick, watch,
} from '@vue/composition-api';
import PropertyTypeField from '@/components/property/PropertyTypeField';
import debounce from 'lodash.debounce';
import { showErrorNotify } from '@/utils/NotifyUtil';
import cloneDeep from 'lodash.clonedeep';

export default {
  name: 'AddUnitDialog',
  components: {
    PropertyTypeField,
  },
  props: {
    property: {
      type: Object,
      required: false,
      default: null,
    },
    visible: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, context) {
    const dispatch = context.root.$store.dispatch;
    const uiFlags = reactive({
      isAdding: false,
      isInitiallySingleUnit: props.property?.units?.length === 1,
    });
    const firstUnitName = props.property?.units[0].name;
    const unitNames = ref(!uiFlags.isInitiallySingleUnit || firstUnitName ? [''] : ['', '']);
    const unitNamesRef = ref([]);
    const withPropertyTypeDropdown = ref(uiFlags.isInitiallySingleUnit);
    const isUnitsNameFilled = computed(() => unitNames.value?.every((unit) => !!unit));
    const focusLastUnitNameInputD = debounce(focusLastUnitNameInput, 250);

    return {
      unitNames,
      unitNamesRef,
      isUnitsNameFilled,
      withPropertyTypeDropdown,
      firstUnitName,
      addUnitToArray,
      removeUnitFromArray,
      add,
      close,
      uiFlags,
      isDeleteableField,
    };

    function addUnitToArray() {
      unitNames.value.push('');
      focusLastUnitNameInputD();
    }

    function focusLastUnitNameInput() {
      nextTick(() => {
        const addedUnitNameRef = document.querySelector(`#unit-name-add-${unitNames.value.length - 1}`);
        addedUnitNameRef.focus();
      });
    }

    function removeUnitFromArray(index) {
      unitNames.value = unitNames.value.filter((unit, unitIndex) => index !== unitIndex);
    }

    async function add() {
      uiFlags.isAdding = true;
      const nextPropertyWithNewUnit = cloneDeep(props.property);
      unitNames.value.forEach((name, unitIndex) => {
        if (unitIndex === 0 && (uiFlags.isInitiallySingleUnit && !firstUnitName)) {
          nextPropertyWithNewUnit.units[0].name = unitNames.value[0];
        } else {
          nextPropertyWithNewUnit.units.push({ name });
        }
      });
      try {
        await dispatch('Property/update', nextPropertyWithNewUnit);
        context.emit('close', { status: 'success', message: 'Units created successfully' });
        clearNames();
      } catch (error) {
        showErrorNotify(context, error.message);
      } finally {
        uiFlags.isAdding = false;
      }
    }

    function close() {
      clearNames();
      context.emit('close');
    }

    function clearNames() {
      unitNames.value = unitNames.value.map((_) => '');
    }

    function isDeleteableField(index) {
      return (uiFlags.isInitiallySingleUnit && index > 1) || (!uiFlags.isInitiallySingleUnit && index > 0);
    }
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .el-dialog {
    max-width: 545px;
  }
}
.units-addition-form {
  max-height: 350px;
  overflow-y: auto;
}

@include media-breakpoint-down(md) {
  .units-addition-form {
    max-height: 50vh;
  }
}
</style>

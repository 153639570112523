<template>
  <router-link
    :to="getPropertyRoute()"
    class="property-card-anchor"
    @click.native.capture="linkClicked"
  >
    <div
      class="property-card"
      :class="`${layout}-layout`"
    >
      <div
        v-if="!(isImportedAndOnMarket && isIntegrated)"
        class="actions"
      >
        <ElDropdown
          ref="actionsDropDown"
          trigger="click"
          hide-on-click
          class="pointer"
          @click.native.prevent
        >
          <div>
            <i class="sdicon-more-vert" />
          </div>
          <ElDropdownMenu>
            <ElDropdownItem
              v-if="!$store.getters['Auth/accountIsInactive']"
              @click.native="showBookAServiceDialog"
            >
              Book a service
            </ElDropdownItem>
            <ElDropdownItem
              v-if="isSingle && isActive"
              @click.native="showAddInquiryDialog"
            >
              Add inquiry
            </ElDropdownItem>
            <ElDropdownItem
              v-if="(!isIntegrationEnabled || isLegacyIntegrationEnabled)"
              @click.native="showAddUnitDialog"
            >
              Add units
            </ElDropdownItem>
            <router-link
              v-if="isSingle"
              :to="{
                name: 'app.units.show',
                params: { id: property.id, unit_id: property.units[0].id },
                query: { tab: 'listing' },
              }"
            >
              <ElDropdownItem>
                Edit
              </ElDropdownItem>
            </router-link>
            <ElDropdownItem
              v-if="getIsPaused(property)"
              @click.native="showUnitPauseDialog(false)"
            >
              Pause {{ isOpenHouse ? 'open house' : 'showing' }}
            </ElDropdownItem>
            <template v-if="isSingle && isActive && get(property, 'units.0.active_showing_profile.paused_until')">
              <ElDropdownItem @click.native="showUnitUnpauseDialog">
                Unpause {{ isOpenHouse ? 'open house' : 'showing' }}
              </ElDropdownItem>
              <ElDropdownItem @click.native="showUnitPauseDialog(true)">
                Update pause end time
              </ElDropdownItem>
            </template>
            <ElDropdownItem
              v-if="isSingle && isActive"
              @click.native="showUnitStopDialog"
            >
              Stop {{ isOpenHouse ? 'open house' : 'showing' }}
            </ElDropdownItem>
            <ElDropdownItem
              v-if="!isActive && (!isIntegrationEnabled && !isLegacyIntegrationEnabled)"
              @click.native="addDeletePropertyDialog"
            >
              Delete property
            </ElDropdownItem>
          </ElDropdownMenu>
        </ElDropdown>
      </div>
      <div
        class="picture"
        :style="thumbnailUrl ? `background-image: url(${thumbnailUrl})` : ''"
      >
        <div
          v-if="isSingle && isActive"
          class="time-in-market"
        >
          <div>
            <span>{{ daysInMarket }}</span>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="address">
          {{ property.short_address }}
          {{ property.units.length === 1 && property.units[0].name ? ' - ' + property.units[0].name : '' }}
        </div>
        <div
          v-if="isSingle"
          class="inquiries-tours-count font-13"
        >
          <div v-if="isActive">
            <UnitActivityCounters :unit="property.units[0]" />
          </div>
        </div>
        <div
          v-else
          class="units row align-items-center gutter-0.5"
        >
          <div class="col-auto">
            <div class="count">
              <i class="sdicon-home" />
              {{ property.units.length }} units
            </div>
          </div>
          <div
            :class="layout === 'grid' ? 'col' : ''"
            class="names font-13"
          >
            {{
              property.units.map(unit => unit.name)
                .join(', ')
            }}
          </div>
          <div class="col-auto">
            <router-link
              class="font-13"
              :to="{ name: 'app.units', params: { id: property.id } }"
            >
              View all →
            </router-link>
          </div>
        </div>
      </div>
      <div class="footer">
        <template v-if="isSingle">
          <template v-if="isActive && get(property, 'units.0.active_showing_profile.paused_until')">
            <div class="status paused mr-2">
              Paused
            </div>
            <i
              v-if="property.units[0].status === 'auto_paused'"
              class="sdicon-exclamation-triangle text-orange mr-1"
            />
            <span class="font-11 text-danger">
              {{ getUnitPausedLabel(property.units[0], property.timezone) }}
            </span>
          </template>
          <template v-else-if="isActive">
            <ElTooltip
              v-if="hasIdentityVerification"
              popper-class="action-popper-class"
              placement="top"
            >
              <template slot="content">
                <p class="text-center">
                  AI Identity Verification Enabled
                </p>
              </template>
              <i
                :class="layout === 'grid' ? '' : 'list-view-identity-verification'"
                class="sdicon-fingerprint text-success mr-1 font-21"
              />
            </ElTooltip>
            <div class="status active font-11">
              {{ activeStatusText }}
            </div>
            <span
              v-if="isSelfShowing"
              class="black-tag"
            >
              <i class="sdicon-key position-absolute self-showing-key" />
              <span class="pl-4 text-gray-dark">SELF SHOWING</span>
            </span>
            <span
              v-else-if="isShowingProfileNotPublishListing"
              class="upcoming-tours"
            >
              {{ upcomingDetailsRef }}
            </span>
          </template>
          <template v-else>
            <div class="status inactive">
              Not Showing
            </div>
            <div
              v-if="property.imported_at"
              class="font-13 text-gray-darker imported-at"
            >
              {{ resolveImportedType() }}
            </div>
          </template>
        </template>
        <div
          v-else
          class="units-counters"
        >
          <div>
            <span class="dot bg-success mr-2" />{{ unitsCounters.active }} Showing
          </div>
          <div>
            <span class="dot bg-danger mr-2" />{{ unitsCounters.paused }} Paused
          </div>
          <div>
            <span class="dot bg-gray mr-2" />{{ unitsCounters.inactive }} Not showing
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import { computed, ref } from '@vue/composition-api';
import UnitActivityCounters from '@/components/unit/UnitActivityCounters.vue';
import pluralize from 'pluralize';
import get from 'lodash.get';
import momentUtil from '@/utils/MomentUtil';
import { resolvePictureUrl } from '@/utils/PropertyUtil';
import Showing from '@/constants/Showing';
import { getUnitPausedLabel } from '@/utils/UnitUtil';
import { BusinessSourceTypes } from '@/constants/BusinessSource';
import { SHOWING_PROFILE_ROUTES } from '@/router/routes';
import { isBusinessOnlyUsingAgentShowings } from '@/utils/BusinessUtil';
import { capitalizeFirstLatter } from '@/utils/StringUtil';

export default {
  name: 'PropertyCard',
  components: { UnitActivityCounters },
  props: {
    property: {
      type: Object,
      required: true,
    },
    layout: {
      type: String,
      required: false,
      default: 'grid',
    },
  },
  setup(props, context) {
    const {
      $store,
    } = context.root;
    const isShowingProfilePublishListing = ref(get(props.property, 'units.0.active_showing_profile.type') === Showing.TYPE_PUBLISH_LISTING);
    const isShowingProfileNotPublishListing = ref(!isShowingProfilePublishListing.value);
    const unitsCounters = computed(computeUnitsCounters);
    const isSingle = computed(computeIsSingle);
    const hasIdentityVerification = computed(computeHasIdentityVerification);
    const isActive = computed(computeIsActive);
    const isSelfShowing = computed(computeIsSelfShowing);
    const isOpenHouse = computed(computeIsOpenHouse);
    const isImportedAndOnMarket = computed(computeIsImportedAndOnMarket);
    const isIntegrated = computed(computeIsIntegrated);
    const showEditLinkRoute = computed(computedShowEditLinkRoute);
    const upcomingDetailsRef = computed(computedUpcoming);
    const showEditLinkText = computed(computedShowEditLinkText);
    const thumbnailUrl = computed(() => resolvePictureUrl(props.property));
    const daysInMarket = computed(computeDaysInMarket);
    const activeStatusText = computed(computeActiveStatusText);
    const isIntegrationEnabled = $store.getters['BusinessSource/isIntegrationEnabled'];
    const isLegacyIntegrationEnabled = $store.getters['BusinessSource/isLegacyIntegrationEnabled'];
    const businessSource = context.root.$store.getters['BusinessSource/get'];

    return {
      get,
      unitsCounters,
      isActive,
      isSelfShowing,
      isOpenHouse,
      isSingle,
      isImportedAndOnMarket,
      showEditLinkRoute,
      isIntegrationEnabled,
      isLegacyIntegrationEnabled,
      thumbnailUrl,
      daysInMarket,
      getUnitPausedLabel,
      getPropertyRoute,
      linkClicked,
      showBookAServiceDialog,
      showAddInquiryDialog,
      showAddUnitDialog,
      addDeletePropertyDialog,
      showUnitPauseDialog,
      showUnitUnpauseDialog,
      showUnitStopDialog,
      presentableImportedAt,
      upcomingDetailsRef,
      hasIdentityVerification,
      showEditLinkText,
      getIsPaused,
      activeStatusText,
      isShowingProfileNotPublishListing,
      resolveImportedType,
      isIntegrated,
    };

    function resolveImportedType() {
      if (!businessSource?.source_type) {
        return '';
      }
      const type = capitalizeFirstLatter(BusinessSourceTypes[businessSource.source_type.toUpperCase()]);
      const importedAt = presentableImportedAt(props.property.imported_at);
      return `Imported from ${type} on ${importedAt}`;
    }

    function getPropertyRoute(queryParams = {}) {
      const isSingle = computeIsSingle();
      return {
        name: isSingle ? 'app.units.show' : 'app.units',
        params: {
          id: props.property.id,
          unit_id: isSingle ? props.property.units[0].id : undefined,
        },
        query: queryParams,
      };
    }

    function getIsPaused(property) {
      return isShowingProfileNotPublishListing.value
        && isSingle.value
        && isActive.value
        && get(property, 'units.0.active_showing_profile.paused_until') === null;
    }

    function computedShowEditLinkText() {
      let actionText = 'Start';
      let typeText = computeIsOpenHouse() ? 'open house' : 'showing';

      if (props.property.is_null_location) {
        actionText = 'Edit';
        typeText = 'property';
      }

      return `${actionText} ${typeText}`;
    }

    function computedShowEditLinkRoute() {
      return props.property.is_null_location ? getPropertyRoute({ tab: 'listing' }) : getShowingRoute();
    }

    function computedUpcoming() {
      const upcomingToursCount = get(props.property, 'units.0.active_showing_profile.upcoming_tours_count', 0);
      const isOpenHouse = get(props.property, 'units.0.active_showing_profile.is_open_house', 0);
      const text = isOpenHouse ? 'open house' : 'tour';
      const tourText = pluralize(text, upcomingToursCount);

      return `${upcomingToursCount || 'No'} Upcoming ${tourText}`;
    }

    function linkClicked(event) {
      if (get(context, 'refs.actionsDropDown.visible')) {
        event.preventDefault();
      }
    }

    function computeUnitsCounters() {
      const active = props.property.units.filter((unit) => unit.active_showing_profile && unit.status !== 'paused').length;
      const paused = props.property.units.filter((unit) => get(unit.active_showing_profile, 'paused_until')).length;

      return {
        active,
        paused,
        inactive: props.property.units.length - active - paused,
        completed: get(props.property, 'units.0.active_lease_period.completed_tours_count', 0),
      };
    }

    function computeDaysInMarket() {
      const activeLeasePeriod = get(props.property, 'units[0].active_lease_period');
      if (!activeLeasePeriod) {
        return null;
      }
      const tz = props.property.timezone;
      const startDate = momentUtil(activeLeasePeriod.created_at, tz);
      const now = momentUtil(null, tz);
      const daysDiff = now.diff(startDate, 'days');
      return `${daysDiff} Active ${pluralize('day', daysDiff)}`;
    }

    function computeIsIntegrated() {
      return Boolean(businessSource && (businessSource.client_id || businessSource.propexo_integration_id));
    }

    function computeIsSingle() {
      return props.property.units.length === 1;
    }

    function computeHasIdentityVerification() {
      if (computeIsSingle()) {
        return get(props.property, 'units.0.active_lease_period.has_identity_verification');
      }
      return false;
    }

    function computeIsActive() {
      return !!get(props.property, 'units', [])
        .some((unit) => !!unit.active_showing_profile);
    }

    function computeIsSelfShowing() {
      return !!get(props.property, 'units.0.active_showing_profile.is_self_showing');
    }

    function computeIsOpenHouse() {
      return !!get(props.property, 'units.0.active_showing_profile.is_open_house');
    }

    function computeIsImportedAndOnMarket() {
      return !isActive.value && props.property.imported_at;
    }

    function computeActiveStatusText() {
      if (isOpenHouse.value) {
        return Showing.STATUS_OPEN_HOUSE;
      }
      if (isShowingProfilePublishListing.value) {
        return Showing.STATUS_PUBLISH_LISTING;
      }
      return Showing.STATUS_SHOWING;
    }

    function getShowingRoute() {
      if (isSingle.value) {
        return {
          name: isBusinessOnlyUsingAgentShowings() ? SHOWING_PROFILE_ROUTES.AGENT_SHOWING_PAGE : SHOWING_PROFILE_ROUTES.TYPE_SELECTION_PAGE,
          params: {
            id: props.property.id,
            unit_id: props.property.units[0].id,
          },
        };
      }

      return {
        name: SHOWING_PROFILE_ROUTES.TYPE_READY_TO_START_PAGE,
        params: { id: props.property.id },
      };
    }

    function showBookAServiceDialog() {
      const data = {
        dialog: 'book-a-service',
        property: props.property,
      };
      if (isSingle.value) {
        data.unit = props.property.units[0];
      }
      context.emit('show-dialog', data);
    }

    function showAddInquiryDialog() {
      const data = {
        dialog: 'add-inquiry',
        unit: {
          ...props.property.units[0],
          property: props.property,
        },
      };
      context.emit('show-dialog', data);
    }

    function showAddUnitDialog() {
      const data = {
        dialog: 'add-unit',
        property: props.property,
      };
      context.emit('show-dialog', data);
    }

    function showUnitPauseDialog(isEdit) {
      const data = {
        dialog: 'unit-pause',
        unit: {
          ...props.property.units[0],
          property: props.property,
        },
        isEdit,
      };
      context.emit('show-dialog', data);
    }

    function showUnitUnpauseDialog(isEdit) {
      const data = {
        dialog: 'unit-unpause',
        unit: {
          ...props.property.units[0],
          property: props.property,
        },
        isEdit,
      };
      context.emit('show-dialog', data);
    }

    function showUnitStopDialog() {
      const data = {
        dialog: 'unit-stop',
        unit: {
          ...props.property.units[0],
          property: props.property,
        },
      };
      context.emit('show-dialog', data);
    }

    function addDeletePropertyDialog() {
      const data = {
        dialog: 'delete-property',
        property: props.property,
      };
      context.emit('show-dialog', data);
    }

    function presentableImportedAt(importedAt) {
      return momentUtil(importedAt, null)
        .toDisplayMonthAndDayFormat();
    }
  },
};
</script>

<style lang="scss" scoped>
a.property-card-anchor {
  display: block;
  height: 100%;
  transition: $app-transition;

  &:hover {
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);
  }
}

.property-card {
  border-radius: $app-border-radius;
  background: $white;
  color: gray-color('darker');
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  > .actions {
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
    z-index: 1;
    background: $white;
    border-radius: 2px;
    text-align: center;
    transition: $transition-base;

    i {
      transition: $transition-base;
      font-size: $--font-size-medium-large;
      color: gray-color(dark);
      margin-top: -5px;
      margin-left: -2px;

      &.close-icon {
        margin-top: 0.1rem;
        margin-right: -0.1rem;
        font-size: $--font-size-medium;
      }

      &:hover {
        color: gray-color(darker);
      }

      &:before {
        margin: 0;
        transform: translateY(-3.5px);
      }
    }
  }

  > .close-icon {
    position: absolute;
    top: 0.5rem;
    right: 0.25rem;
    z-index: 1;
  }

  > .picture {
    height: 100px;
    background-size: cover;
    background-position: center center;
    background-image: url('../../assets/property-image-fallback.svg');
    position: relative;
    display: flex;
    flex-direction: column;

    .start-showing {
      background-color: rgba(0, 0, 0, 0.65);
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;

      > .show-btn {
        border-radius: $app-border-radius;
        color: $white;
        border: 1px solid $white;
        padding: 0.5rem 1rem;

        &:hover {
          background-color: rgba(255, 255, 255, 0.35);
        }
      }
    }

    img {
      width: 28px;
      height: 28px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
    }

    .time-in-market {
      margin: 0.75rem;
      margin-right: 2.5rem;

      > div {
        color: $white;
        height: 1.5rem;
        background: rgba(23, 23, 23, 0.8);
        display: inline-flex;
        align-items: center;
        border-radius: 4px;
        padding: 0 0.25rem;

        > strong {
          font-size: 11px;
          margin-right: 0.25rem;
        }

        > span {
          font-size: 11px;
        }
      }
    }
  }

  > .content {
    padding: 1rem;
    flex: 1;

    > .address {
      font-weight: bold;
      font-size: 15px;
      margin-bottom: 10px;
    }

    > .inquiries-tours-count {
      i.sdicon-exclamation-triangle:before {
        margin: 0;
      }
    }

    > .units {
      .count {
        text-transform: uppercase;
        font-size: $--font-size-small;
        font-weight: bold;
        padding: 0 0.25rem;
        border-radius: 4px;
        border: 1px solid gray-color(darker);
        display: inline-block;

        i::before {
          margin: 0;
        }
      }

      > .names {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: gray-color('dark');
      }
    }
  }

  > .footer {
    min-height: 57px;
    padding: 0.75rem 1rem;
    border-top: 1px solid gray-color(light);
    display: flex;
    align-items: center;

    > .status {
      padding: 0.25rem 0.5rem;
      border-radius: 4px;
      color: $white;
      display: inline-block;
      font-size: $--font-size-small;
      font-weight: bold;
      text-transform: uppercase;

      &.active {
        background: theme-color(success);
      }

      &.paused {
        background: theme-color(danger);
      }

      &.inactive {
        background: gray-color();
      }
    }

    > .upcoming-tours {
      font-size: 11px;
      margin-left: 0.5rem;
      width: 7rem;
      text-align: center;
    }

    > .units-counters {
      text-transform: uppercase;
      font-size: 11px;
      font-weight: bold;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .dot {
        width: 5px;
        height: 5px;
        border-radius: 1px;
        margin: 1px 0;
      }
    }

    .imported-at {
      width: 9rem;
      padding-left: 0.5rem;
      line-height: 15px;
    }
  }
}

.property-card.list-layout {
  display: grid;
  grid-template-areas:
    "picture content actions"
    "picture footer footer";
  grid-template-columns: auto 1fr auto;
  justify-content: start;

  @include media-breakpoint-up(md) {
    grid-template-areas: "picture content footer actions";
    grid-template-columns: auto 1fr 1fr auto;
  }

  > .picture {
    grid-area: picture;
    width: 170px;
    min-height: 72px;
    height: auto;
    background-size: cover;

    > .time-in-market {
      width: 33px;
      height: 100%;
      margin: 0;

      > div {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        border-radius: 0;

        > strong {
          margin-right: 0;
        }

        > span {
          font-size: 9px;
        }
      }
    }
  }

  > .content {

    grid-area: content;

    .names {
      margin-top: .5rem;
      max-width: 10rem;
    }
  }

  > .footer {
    grid-area: footer;

    .list-view-identity-verification {
      margin-left: -30px;
    }

    @include media-breakpoint-up(md) {
      border: 0;
    }
  }

  > .actions {
    grid-area: actions;
    width: auto;
    height: auto;
    position: static;
    display: flex;
    align-items: center;
    background: initial;

    @include media-breakpoint-down(md) {
      align-self: start;
      margin-top: 0.75rem;
    }

    i {
      color: gray-color('dark') !important;

      &:hover {
        color: $link-color !important;
      }

      &::before {
        text-shadow: none;
      }
    }
  }
}
</style>

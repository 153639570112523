<template>
  <ElDialog
    :center="true"
    top="30vh"
    :show-close="false"
    :visible="true"
    :width="$viewport.lt.md ? 'auto': '500px'"
    :fullscreen="$viewport.lt.md"
    @close="removeWelcome"
  >
    <SdSpinner v-if="!user" />
    <div
      v-else
      class="text-center"
    >
      <div class="confeti-holder">
        <img src="@/assets/confeti-house.svg">
      </div>
      <h1 class="font-21 mt-4">
        <span class="capitalize">{{ user.first_name }}</span>, Welcome to Showdigs!
      </h1>
      <p class="mt-3">
        Your Showdigs subscription is now active
      </p>
      <ElButton
        class="mt-3"
        size="large"
        type="primary"
        @click="removeWelcome"
      >
        Let's get started
      </ElButton>
    </div>
  </ElDialog>
</template>

<script>
import { computed } from '@vue/composition-api';
import SdSpinner from '@/components/common/SdSpinner';

export default {
  name: 'WelcomeNewSubscriberDialog',
  components: { SdSpinner },
  props: {},
  setup(props, context) {
    const user = computed(() => context.root.$store.getters['Auth/user']);
    return {
      user,
      removeWelcome,
    };

    function removeWelcome() {
      const query = { ...context.root.$route.query };
      delete query.welcome;
      context.root.$router.replace({ query });
    }
  },
};
</script>

<style lang="scss" scoped>
.confeti-holder {
  margin: 0 -2rem;
  border-bottom: 1px solid gray-color('light');
  img {
    margin-bottom: -4px;
  }
}
</style>

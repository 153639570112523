const Showing = {
  DEACTIVATION_TYPE_RENTED_WITH_SHOWDIGS: 1,
  DEACTIVATION_TYPE_RENTED_WITH_SHOWDIGS_UNKNOWN_LEAD: 2,
  DEACTIVATION_TYPE_RENTED_WITHOUT_SHOWDIGS: 3,
  DEACTIVATION_TYPE_OTHER: 4,
  DEACTIVATION_TYPE_AUTO_BUDGET_REACHED: 5,
  IMPACT_TYPE_CANCELLABLE: 'canBeCancelled',
  IMPACT_TYPE_NOT_CANCELLABLE: 'cannotBeCancelled',
  STATUS_ACTIVE: 'active',
  STATUS_DEACTIVATED: 'deactivated',
  STATUS_PAUSED: 'paused',
  STATUS_SHOWING: 'showing',
  STATUS_OPEN_HOUSE: 'open house',
  STATUS_PUBLISH_LISTING: 'third-party',
  STATUS_NOT_SHOWING: 'not showing',
  TYPE_SELF_SHOWING: 'self_showing',
  TYPE_HIGH_AVAILABILITY: 'high_availability',
  TYPE_OPEN_HOUSE: 'open_house',
  TYPE_RESTRICTED: 'restricted',
  TYPE_PUBLISH_LISTING: 'publish_listing',
  TYPE_AGENT_SHOWING: 'agent_showing',
};

export default Showing;
